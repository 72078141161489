html {
    scrollbar-color: #093a5c #fff !important;
    scrollbar-width: thin;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* main margin responsive */
main {
    margin-top: 150px;
}
@media (max-width: 800px) {
    main {
        margin-top: 90px;
    }
}